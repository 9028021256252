
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Arkitech Medium";
  src: local("Arkitech Medium"),
    url("./assets/fonts/Arkitech\ Medium.ttf") format("truetype");
}@font-face {
  font-family: "Arkitech";
  src: local("Arkitech"),
    url("./assets/fonts/Arkitech.ttf") format("truetype");
}
@font-face {
  font-family: "Inter";
  font-weight: normal;
  src: local("Inter"),
    url("./assets/fonts/Inter.otf") format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: local("Inter-Medium"),
    url("./assets/fonts/Inter-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: local("Inter-SemiBold"),
    url("./assets/fonts/Inter-SemiBold.otf") format("opentype");
}
@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: local("Inter-Bold"),
    url("./assets/fonts/Inter-Bold.otf") format("opentype");
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat.ttf") format("truetype");
}@font-face {
  font-family: "Kelson";
  src: local("Kelson"),
    url("./assets/fonts/Kelson\ Sans\ Regular.otf") format("opentype");
}