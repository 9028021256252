$tabletAndMobileSize: 760px;

.footerWrapper {
  background-color: rgba(27, 27, 27, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  width: 100%;
  padding: 1vw 0;
  .sections {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .firstSection {
      align-items: center;
    }
    .secondSection {
      >div {
        display: flex;
        gap: 1vw;
        align-items: center;
        padding-right:75px;
      }
    }
    .mobileCopyright {
      display: none;
    }
  }
  img {
    width: 1.13vw;
    height: auto;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 14vh;
    padding: 2vh 2vh 0.5vh 2vh;
    width: auto;
    img {
      width: 21px;
      height: auto;
    }
    .sections {
      width: 90%;
      flex-direction: column;
      .mobileCopyright {
        display: flex;
        font-size: 12px;
        align-items: center;
        img {
          margin-left: 5px;
        }
      }
      .firstSection {
        > img {
          display: none;
        }
        > p {
          display: none;
        }
      }
      .firstSection {
        margin-left: 0;
      }
      .secondSection {
        width: 100%;
        justify-content: space-between;
        margin: 5%;
        text-align: center;
        flex-direction: column;
        > * {
          flex: 1;
        }
        span {
          display: block;
          font-size: 12px;
        }
        a {
          font-size: 12px;
        }
        >div {
          padding-right: 0;
          gap: 2vw;
          >div {
            display: none;
          }
        } 
      }
    }
  }
}

.firstSection {
  display: flex; 
  .languageSection {
    margin-left: 10px;
    display: flex;
    .language {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }
    .languageDisabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  p {
    margin: 0;
    font-size: 0.9vw;
    @media only screen and (max-width: $tabletAndMobileSize) {
      font-size: 0.5rem;
    }
  }
}
.secondSection {
  display: flex;
  gap: 1vw;
  align-items: center;
  padding-right:75px;
  > span {
    display: none;
  }
  img {
    width: 0.97vw;
    height: 0.91vw;
  }
  p {
    margin: 0;
    font-size: 0.9vw;
  }
  a, div {
    text-decoration: none;
    font-size: 0.9vw;
    color: #FFFFFF;
  }
  > div {
    cursor: pointer;
  }

  @media only screen and (max-width: $tabletAndMobileSize) {
    padding-right: 0;
    padding-left: 3px;
    p{
      font-size: 0.75vmax;
    }
    img {
      width: 21px;
      height: auto;
    }
    a {
      font-size: 0.5rem;
    }
    > div {
      padding-right: 0;
      gap: 2vw;
    }
  }
}
.flagsWrapper {
  display: flex;
  width: 7vw;
  align-items: center;
  justify-content: space-between;
  img {
    width: 0.86vw;
    height: 0.48vw;
  }
  
  p {
    font-size: 0.64vw; 
  }

  @media only screen and (max-width: $tabletAndMobileSize) {
    width: 30px;
    gap: 4px;
    p {
      font-size: 0.75vmax;
    }
    img {
      width: 16px;
      height: 9px;
    }
  }
}
