.notFoundHeading{
  color: red;
}

.headingStyle {
  color: rgba(255, 255, 255, 0.7);
  font-weight: normal;
  margin-top: 15%;
  margin-bottom: 0;
}

.linksStyle {
  color: rgba(255, 255, 255, 0.7);
  a { 
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }
}