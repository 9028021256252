$tabletAndMobileSize: 760px;
$XXLSize: 1400px;

.heroWrapper {
  width: 100%;
  height: 56vw;
  position: relative;
  box-shadow: inset 0px 70px 100px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 69vmax;
  }
}
body #tarteaucitronRoot div#tarteaucitronAlertBig {
  box-shadow: none;
}
#tarteaucitronRoot button {
  border-radius: 5px;
}

div#tarteaucitronServices{
  border-radius: 5px;
}

.heroWrapperBrandLandingPage {
  width: 100%;
  height: 56vw;
  display: hidden;
  position: relative;
  animation: backgroundChange 1.4s ease-in-out;

  @media only screen and (max-width: $tabletAndMobileSize) {
    height: auto;
  }
}
.display {
  display: unset;
}
.backgroundImage {
  max-width: 100%;
  height: 100%;
  margin-top: -18%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: auto;
    width: 100%;
    transform: scale(1.5);
    margin-top: 2%;
  }
}
.backgroundImageAnimation {
  // animation: backgroundChange 1.4s ease-in-out;
}

@keyframes backgroundChange {
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}
.svgLogo,
.logo {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 0;

  // img {
  //   width: auto;
  //   height: 40px;
  //   margin-top: 40px;

  //   @media only screen and (max-width: $tabletAndMobileSize) {
  //     margin-top: 3.38vh;
  //   }
  // }
}
.logoImage {
  width: auto;
  height: 40px;
  margin-top: 40px;

  @media only screen and (max-width: $tabletAndMobileSize) {
    width: 143px;
    height: auto;
    margin-top: 4.38vh;
  }
}
.blackSection1 {
  background-color: #F0F0F0;
  height: 34vw;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 290vw;
  }
  @media only screen and (min-width: $XXLSize) {
    height: 25vw;
  }
}

.blackSection2 {
  background-color: #F0F0F0;
  height: 30vw;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 150vw;
  }
}

.buttonPosition {
  position: absolute;
  height: 40px;
  top: 40px;
  right: 6.04vw;
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: unset;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    top: 3.38vh;
    right: 2vh;
  }
}

div#tarteaucitronAlertBig:before {
  display: none;
}

.textArea {
  width: 90%;
  position: absolute;
  top: 56%;
  border: 1px solid #F0F0F0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  border-radius: 5px;
  .chartArea{
    display: flex;
    border-top: 1px solid #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
    margin-bottom: 3%;

    div {
      width: 33%;
      padding: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #F0F0F0;
      position: relative;

      span {
        position: absolute;
        text-align: center;
      }

      .chartNo {
        font-family: 'Inter';
        font-weight: 600;
        font-size: 1.6vw;
        color: #151515;
        margin-top: 0;
      }

      p {
        font-family: 'Inter';
        font-weight: 400;
        color: #7E7E7E;
        font-size: 0.9vw;
        margin-bottom: 0;
        margin-top: 5%;
      }
    }
  }
  .designersTitle {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #F0F0F0;
    color: #7E7E7E;
    font-family: "Arkitech Medium";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    padding: 0.7% 5%;
  }
  .flexArea{
    display: flex;
    padding: 3% 5%;
    justify-content: space-between;
    align-items: center;
    .designersDescription{
      h1 {
        margin-top: 0;
      }
    }
    img {
      max-width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
  top: 105%;
  .designersTitle {
    padding: 2% 5%;
    font-size: 10px;
  }


    .flexArea{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        max-width: 30%;
        width: auto;
        object-fit: contain;
        margin-top: 7%;
        margin-bottom: 7%;
        height: auto;
      }
    }
    .chartArea{
      flex-direction: column;
      div {
        width: auto;
        border-right: none;
        border-bottom: 1px solid #F0F0F0;
        img {
          height: 138px;
          width: 138px;
        }
        .chartNo {
          font-size: 6vw;
        }
        p {
          font-size: 3vw;
        }
      }
    }
  }
  // @media only screen and (min-width: $XXLSize) {
  //   width: 65%;
  // }
}

.titleAndIcon {
  display: flex;
  width: 100%;
  align-items: center;
  z-index: 2;
  img {
    width: 40px;
    height: 40px;
  }
  p {
    margin: 0 0.3vw;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    img {
      width: 2vmax;
      height: 2vmax;
    }
  }
}
.titleText {
  font-family: "Arkitech Medium";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;


  // @keyframes backgroundChange {
  //   0% {
  //     opacity: 0.2;
  //   }

  //   100% {
  //     opacity: 1;
  //   }
  // }
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 1.75vmax;
  }
}

.colorHero {
  color: #ffab47;
}

.colorBrands {
  color: #9277FF
}

.titleTextAnimation {
  animation: backgroundChange 1.4s ease-in-out;
}
.titleAndIconAnimation {
  img {
    animation: svg 1.4s linear;
  }
}
.brandsAnimation {
  animation: textAnimation 1.4s ease-in-out;
}
@keyframes textAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.description {
  // width: 100%;
  font-family: "Inter";
  // font-style: normal;
  font-weight: 600;
  font-size: 1.62vw;
  // text-align: justify;
  // letter-spacing: -0.04em;
  // color: #ffffff;
  // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // animation: color-change-orange 1.4s ease-in;
  z-index: 2;

  @keyframes color-change-orange {
    0% {
      color: #ffab47;
    }
    100% {
      color: #ffffff;
    }
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 18px;
    max-width: 87%;
    line-height: 28px;
    height: 100px;
    align-items: center;
    display: flex;
  }
}
.brandsTitle {
  background-color: #F0F0F0;
  color: #7E7E7E;
  font-family: "Arkitech Medium";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  padding: 0.3% 0;
  text-align: center;
}

.descriptionHeroCommon {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 24px;
  /* or 133% */
  width: 35%;
  color: #7E7E7E;
  z-index: 2;
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 12px;
    max-width: 87%;
    width: auto;
    line-height: 24px;
  }
}
.descriptionHeroCommonAnimationOrange {
  // animation: color-change-orange 1.4s ease-in;
}
.descriptionHeroCommonAnimationPurple {
  // animation: color-change-purple 1.4s ease-in;
}

@keyframes color-change-orange {
  0% {
    color: #ffab47;
  }
  100% {
    color: #ffffff;
  }
}
@keyframes color-change-purple {
  0% {
    color: #9277ff;
  }
  100% {
    color: #ffffff;
  }
}

.descriptionBrand {
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.62vw;
  text-align: justify;
  letter-spacing: -0.04em;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;

  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 18px;
    max-width: 87%;
    line-height: 28px;
    height: 100px;
    align-items: center;
    display: flex;
  }
}
.descriptionBrandAnimation {
  animation: colorChange 1.4s ease-in-out;
}
@keyframes colorChange {
  0% {
    color: #9277ff;
  }
  100% {
    color: #ffffff;
  }
}
.bulletPointText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9vw;
  color: #7E7E7E;
  // padding-inline-start: 22px;
  // margin: 0;
  // z-index: 2;

  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 12px;
    line-height: 24px;
  }
}

.bulletPointTextAnimation {
  animation: backgroundChange 1.4s ease-in-out;
}
.buttonSection {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 3%;
  // position: absolute;
  bottom: 3.84vw;
  button {
    background: linear-gradient(180deg, rgba(255, 171, 71, 0) 0%, rgba(255, 171, 71, 0.15) 100%);
  }
  a {
    text-decoration: none;
  }
  .findMoreButton {
    background-color: black;
    border: none;
    color: white;
    width: 330px;
    border: 1px solid black;

    img {
      width: 4%;
      mix-blend-mode: exclusion;
    }
    @media only screen and (max-width: $tabletAndMobileSize) {
      width: 180px;
      img {
        width: 6%;
      }
    }
  }
  .findMoreButton:hover {
    background-color: white;
    color: black;
    height: 40px;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
.architectsButton {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2% 0;
  @media only screen and (max-width: $tabletAndMobileSize) {
    margin: 5% 0;
  }
  .findMoreButton {
    img {
      width: 5%;
      mix-blend-mode: exclusion;
    }
  }
}

// BRANDS SECTIONS
.brandsDescriptionWrapper {
  z-index: 2;
  background: rgba(27, 27, 27, 0.3);
  background-color: #ffffff;
  // height: 90vh;
  @media only screen and (max-width: $tabletAndMobileSize) {
    .backgroundRepeat {
      background-color: transparent;
      height: 300px;
      width: 100%;
      background-image: url('../../../assets/images/grouped-logos.png');
      background-repeat: repeat-x;
      background-size: 1000px auto;
      animation: marquee 14s infinite linear;
    }
    @keyframes marquee {
      0% { background-position: 0; }
      100% { background-position: -1000px; }
    }
    .logoSection {
      display: none;
    }
  }
}
.textAreaBrands {
  padding: 4% 5%;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  position: relative;
  .textArea {
    top: 90%;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    .textArea {
      top: 10%;
    }
  }
}
.backgroundBrandSectionLanding {
  position: absolute;
  z-index: 2;
  height:36.9vw;
  width: 100%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 33vh;
  }
 
}
.backgroundBrandSectionLandingAnimation {
  position: absolute;
  z-index: 2;
  animation: backgroundChange 1.4s ease-in-out;
}
.smallerHeightLogoSection {
  height: 24.5vw;
}
@keyframes backgroundChange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.logoSection {
  display: flex;
  flex-direction: column;
  padding: 3vw 0.5vw;
  width: 88%;
  z-index: 2;
  background-color: #FFFFFF;
  img {
    padding: 0.8vw 0;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    padding: 4.6vw 0.5vw;
  }
}
.cardsInfoWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: $tabletAndMobileSize) {
    flex-direction: column;
    align-items: center;
  }
}

.findOutMoreBrands {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../../assets/images/brandsSectionBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  z-index: 2;
  img {
    padding: 0.8vw 0;
    padding-left: 10px;
  }
}
.buttonSectionBrands {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 10.25vw;
  justify-content: center;
  padding-bottom: 3vw;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 55.73%, rgba(0, 0, 0, 0.331771) 100%);
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 10.25vh;
  }
}

.logoRow {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: $tabletAndMobileSize) {
    flex-wrap: wrap;
    row-gap: 20px;
    margin-top: 20px;
  }

  img {
    height: 2vh;
    width: auto;
    opacity: 0.8;
    @media only screen and (max-width: $tabletAndMobileSize) {
      height: auto !important;
      width: 30%;
      margin: 5%;
    }

  }
}

.logoRowPadding {
  padding: 0.8vw 8vw;
}


.wrapperProductsSection{
  width: 100%;
  display: flex;
  justify-content: center;
  .productsSection {
    .backArrow{
      text-align: left;
      width: 100%;
      margin-left: 2%;
      margin-bottom: 1%;
      img{
        cursor: pointer;
      }
    }
    height: auto;
    width: 90%;
    background-color: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2% 0;
    margin-bottom: 5%;
    @media only screen and (max-width: $tabletAndMobileSize) {
      height: auto;
    }
    // @media only screen and (min-width: $XXLSize) {
    //   width: 65%;
    // }
    .searchField {
      width: 90%;
      background-color: rgba(41, 44, 51, 0.05);
    }
    .productsCardWrapper {
      padding: 2% 0;
      gap: 2%;
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      min-height: 72vh;
      .noProductMatch {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .productCard {
        flex: 32%;
        align-items: center;
        @media only screen and (max-width: $tabletAndMobileSize) {
          flex: 100% 1;
        }
        .productThumbnail {
          @media only screen and (max-width: $tabletAndMobileSize) {
            width: 100%;
          }
          // width: 25vw;
          width: 100%;
          object-fit: contain;
          height: 25vh;
          border-radius: 8px;
          border: 0.5px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }
        h3 {
          margin-bottom: 0;
        }
        h5 {
          margin-top: 0;
        }
      }
    }
    .productViewContainer {
      display: flex;
      height: 100%;
      width: 100%;
      @media only screen and (max-width: $tabletAndMobileSize) {
        flex-direction: column;
      }
      a{
        color: black;
      }
      .ProductViewer_container__gYCCJ {
        height: 60vh;
        width: 98%;
        padding: 0 2%;
        cursor: pointer;

        @media only screen and (max-width: $tabletAndMobileSize) {
          height: auto;
        }

        #idCanvas{
          border-radius:  5px;
        }
      }
      .productViewWrapper {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        @media only screen and (max-width: $tabletAndMobileSize) {
          width: 98%;
          height: auto;
        }
        .productPicture {
          width: 98%;
          height: 60vh;
          object-fit: contain;
          margin-left: 2%;
          border-radius: 5px;
          border: 0.5px solid #EAEAEA;
          border-radius: 5px;
        }
        *::-webkit-scrollbar {
          width: 2px;
        }
        div::-webkit-scrollbar-track {
          background: whitesmoke;
        }
        
        *::-webkit-scrollbar-thumb {
          background-color: #EAEAEA;
          border-radius: 20px;
        }
        .gallery {
          border: 0.5px solid #EAEAEA;
          max-height: 17%;
          border-radius: 5px;
          margin-left: 2%;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 10px;
          margin-top: 10px;
          overflow-x: auto;
          @media only screen and (max-width: $tabletAndMobileSize) {
            height: 40%;
            margin-top: 10px;
            padding: 10px;
          }
          .borderHighlight{
            border: 1px solid black;
          }
          img {
            border: 0.5px solid #EAEAEA;
            border-radius: 10px;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
      .productInformation {
        @media only screen and (max-width: $tabletAndMobileSize) {
          width: 91%;
          margin-top: 5%;
        }
        margin: 0 2%;
        border: 0.5px solid #EAEAEA;
        padding: 2%;
        width: 50%;
        border-radius: 5px;
        .brandInfos{
          display: flex;
          gap: 15px;
          align-items: center;
          h2 {
            margin:0
          }
          h3{
            margin: 0
          }
          img {
            border: 0.5px solid black;
            border-radius: 5px;
            min-width: 18%;
          }
        }
      }
      .flex {
        display: flex;
        align-items: baseline;
        word-break: break-word;
        h4 {
          min-width: 35%;
          @media only screen and (max-width: $tabletAndMobileSize) {
            min-width: 37%;
          }
        }
      }
      .dimensionsContainer {
        margin-bottom: 10px;
      }
      .variations {
        span {
          width: 100%;
          .MuiFormControl-root{
            width: 100%;
            margin: 0;
          }
          .MuiFormControl-root:focus {
            border-color: red !important;
        }
        }
      }
    }
  }
}
.loaderContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .textLoading {
    animation: fadeIn 3s;
    animation-iteration-count: infinite;
  }
}

@keyframes fadeIn {
  0% { opacity: 0.4; }
  50% { opacity: 0.8; }
  100% { opacity: 0.4; }
}

.MuiPagination-ul li:nth-child(6) { 
  display: none;
}

.videoSectionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
}

.videoSection {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  video::-webkit-media-controls-panel {
    background-image: none !important;
    filter: brightness(0.1);
  }
  #addsome_video {
    border: 1px solid white;
    border-radius: 5px;
    width: 100%;
  }
  // @media only screen and (min-width: $XXLSize) {
  //   width: 65%;
  // }
}

.access3D {
  margin-top: 2vw;
  width: 100%;
  :hover {
    background-color: black !important;
    color:white !important;
  }
}

.access3D-button {
  color: black !important;
  font-weight: bold;
  border:2px solid black !important;
}



