$fontSizeWeb: 0.86vw;
$fontSizeMobile: #c6538c;
$tabletAndMobileSize: 760px;

.button {
  width: 7.251vw;
  height: 2.16vw;
  background-color: rgba(27, 27, 27, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  // font-family: "GT America";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 5px;
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: unset;
  }
  span {
    font-size: 28px;
    text-align: center;
  }
  img {
    width: 0.81vmax;
  }
  @media only screen and (max-width: 1000px) {
    width: 11vh;
    height: 3vh;
    font-size: 1.31vmax;
  }
}
.findMoreButton {
  // height: 2.16vw;
  // min-width: 182px;
  height: 40px;
  width: 197px;
  // background: linear-gradient(180deg, rgba(255, 171, 71, 0) 0%, rgba(255, 171, 71, 0.15) 100%);

  border: 1px solid #ffab47;
  border-radius: 5px;
  // width: 180px;
  // height: 2.16vw;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #151515;

  p {
    // margin: 0;
    font-size: 16px !important;
    // font-family: "GT America";
    font-weight: normal !important;
  }
  img {
    padding-left: 10px;
  }
  transition: 0.3s width ease-in-out, 0.3s background ease-in-out, color 0.3s ease-in-out;
}
.findMoreButtonPurple {
  // width: 180px;
  // height: 2.16vw;
  color: black;
  height: 40px;
  width: 197px;
  cursor: pointer;
  font-family: "GT America";
  // background: linear-gradient(
  //   180deg,
  //   rgba(146, 119, 255, 0) 0%,
  //   rgba(146, 119, 255, 0) 6.25%,
  //   rgba(146, 119, 255, 0.17) 100%
  // );
  border: 1px solid #9277ff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  // color: #9277ff;
  img {
    padding-left: 10px;
  }
  p {
    font-size: 16px !important;
    // font-family: "GT America";
    font-weight: normal !important;
  }
  transition: 0.3s width ease-in-out, 0.3s background ease-in-out, color 0.3s ease-in-out;
  @media only screen and (max-width: 1000px) {
  }
}

.findMoreButtonContactModalPurpleAnimation {
  justify-content: flex-start;
  padding-left: 18px;
  color: #9277ff;
  transition: unset;
}

.findMoreButtonPurple:hover {
  mix-blend-mode: hard-light;
  background-color: black;
  border-color: black;
  color: #FFFFFF;
}
.findMoreButtonContactModalPurpleAnimation:hover {
  background: linear-gradient(0deg, rgba(146, 119, 255, 0.33), rgba(146, 119, 255, 0.33));
  text-align: center;
  border: 1px solid #9277ff;
  border-radius: 30px;
  p {
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }
  p,
  img {
    transform: translateX(9vw);
    transition: 0.3s transform ease-in-out, 0.3s;
  }
}
.findMoreButtonContactModalPurpleAnimation:active {
  background: linear-gradient(
    180deg,
    rgba(146, 119, 255, 0) 0%,
    rgba(146, 119, 255, 0) 6.25%,
    rgba(146, 119, 255, 0.17) 100%
  );

  p,
  img {
    transform: translateX(18vw);
    color: #9277ff;
    @media only screen and (max-width: 1000px) {
      transform: translateX(10vw);
    }
  }
  img,
  p {
    transition: 0.5s transform ease-in-out, 0.5s;
  }
  p {
    transition-delay: 80ms;
  }
}

.findMoreButtonContactModalPurpleDisabled {
  width: 180px;
  height: 2.16vw;
  cursor: not-allowed;
  font-family: "GT America";
  background: linear-gradient(
    180deg,
    rgba(146, 119, 255, 0) 0%,
    rgba(146, 119, 255, 0) 6.25%,
    rgba(146, 119, 255, 0.17) 100%
  );
  border: 1px solid #9277ff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #9277ff;
  img {
    padding-left: 10px;
  }
  p {
    font-size: 16px !important;
    // font-family: "GT America";
    font-weight: normal !important;
  }
  transition: 0.3s width ease-in-out, 0.3s background ease-in-out, color 0.3s ease-in-out;
  @media only screen and (max-width: 1000px) {
    // width: 15.7vh;
    height: 3vh;
    font-size: 1.31vmax;
  }

  background: linear-gradient(
    180deg,
    rgba(146, 119, 255, 0) 0%,
    rgba(146, 119, 255, 0) 6.25%,
    rgba(146, 119, 255, 0.17) 100%
  );

  p,
  img {
    transform: translateX(10vw);
    color: #9277ff;
  }
  img,
  p {
    transition: 0.5s transform ease-in-out, 0.5s;
  }
  p {
    transition-delay: 80ms;
  }
}

.findMoreButtonContactModalOrangeAnimation {
  justify-content: flex-start;
  padding-left: 18px;
  color: #ffab47;
  transition: unset;
}

.findMoreButton:hover {
  background-color: black;
  border-color: black;
  color: #FFFFFF;
}

.findMoreButtonContactModalOrangeAnimation:hover {
  background: linear-gradient(0deg, rgba(255, 171, 71, 0.33), rgba(255, 171, 71, 0.33));
  text-align: center;
  border: 1px solid #ffab47;
  border-radius: 30px;
  p {
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }
  p,
  img {
    transform: translateX(9vw);
    transition: 0.3s transform ease-in-out, 0.3s;
  }
}
.findMoreButtonContactModalOrangeAnimation:active {
  background: linear-gradient(180deg, rgba(255, 171, 71, 0) 0%, rgba(255, 171, 71, 0.15) 100%);

  p,
  img {
    transform: translateX(18vw);
    color: #ffab47;
    @media only screen and (max-width: 1000px) {
      transform: translateX(10vw);
    }
  }
  img,
  p {
    transition: 0.5s transform ease-in-out, 0.5s;
  }
  p {
    transition-delay: 80ms;
  }
}
.findMoreButtonContactModalOrangeDisabled {
  height: 2.16vw;
  min-width: 182px;
  background: linear-gradient(180deg, rgba(255, 171, 71, 0) 0%, rgba(255, 171, 71, 0.15) 100%);

  border: 1px solid #ffab47;
  border-radius: 30px;
  width: 180px;
  height: 2.16vw;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: not-allowed;
  color: #ffab47;

  p {
    margin: 0;
    font-size: 16px !important;
    font-weight: normal !important;
  }
  img {
    padding-left: 10px;
  }
  transition: 0.3s width ease-in-out, 0.3s background ease-in-out, color 0.3s ease-in-out;

  @media only screen and (max-width: 1000px) {
    width: 15.7vh;
    height: 3vh;
    font-size: 1.31vmax;
  }
  background: linear-gradient(180deg, rgba(255, 171, 71, 0) 0%, rgba(255, 171, 71, 0.15) 100%);

  p,
  img {
    transform: translateX(10vw);
    color: #ffab47;
  }
  img,
  p {
    transition: 0.5s transform ease-in-out, 0.5s;
  }
  p {
    transition-delay: 80ms;
  }
}

.headerWrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0.5% 5%;
  background-color: #151515;
  position: relative;
  z-index: 100;
  // .button {
  //   border: 1px solid black !important;
  // }
  .firstPart {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .secondPart {
    display: flex;
    gap: 5px;
    .button {
      height: 35px;
    }
    .findMoreButton {
      background-color: white;
      color: black;
      border: 1px solid white;
      height: 35px;
      width: 225px;
      img{
        display: none;
      }
    }
    .findMoreButton:hover {
      background-color: black;
      color: white;
    }
  }
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: unset;
  }
  .headerTitle {
    font-family: "Arkitech Medium";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
  .loginIcon {
    color: white;
    display: none;
  }


  @media only screen and (max-width: $tabletAndMobileSize) {
    .secondPart {
      >div, >a {
        display: none;
      }
    }
    .loginIcon {
      display: block;
    }
    img {
      width: auto;
      height: 3vh;
    }
    svg {
      width: 30px;
    }
    .button {
      width: 13vw;
    }
  }
}

.textAreaWrapperBrandsArchitects {
  margin-top: 11.4vw;
  padding: 2vw;
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 1px;
  @media only screen and (max-width: 1000px) {
    margin-top: 150px;
  }
}

.backgroundImageBrandAndArchitects {
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 70px 100px rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: auto !important;
    width: 100%;
  }
}

.backgroundImageBrandAndArchitectsAnimation {

}
@keyframes backgroundChange {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.brandBackground {
  background-image: url("../../assets/images/heroBrand.png");
  animation: backgroundChange 1.4s ease-in-out;
}
.architectsBackground {
  background-image: url("../../assets/images/heroArchitects.jpg");
  animation: backgroundChange 1.4s ease-in-out;
}

.heroWrapperBrand {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  p {
    margin: 0.47vmax 0;
    text-align: left;
  }
  justify-content: center;
  align-items: flex-start;
  min-height: 90vh;
  animation: backgroundChange 2s ease-in-out;
  @media only screen and (max-width: 1000px) {
    min-height: 40vh;
    img {
      width: 100%;
      height: auto;
    }
    p {
      margin: 2vh 0;
    }
    // height: 69vmax;
  }
}

.titleStyle {
  font-family: "Arkitech Medium";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 0.865vw;
  height: 0.865vw;
  @media only screen and (max-width: 1000px) {
    font-size: 10px;
  }
}
.titleStyleCardAnimation {
  animation: opacity 1.4s ease-in;
}

.brandName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.62vw;
  /* identical to box height, or 125% */
  letter-spacing: -0.02em;
  color: #151515;
  // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 1000px) {
    font-size: 32px;
  }
}
.brandNameCard {
  font-family: "Inter";
  font-weight: 600;
  font-size: 1.62vw;
  color: #151515;
  margin: 0;
  @media only screen and (max-width: 1000px) {
    font-size: 32px;
  }
}
.changeColorOrange {
  // animation: color-change-orange 1.4s ease-out;
}
.changeColorPurple {
  // animation: color-change-purple 1.4s ease-out;
}
@keyframes color-change-orange {
  0% {
    color: #ffab47;
  }
  100% {
    color: #ffffff;
  }
}
@keyframes color-change-purple {
  0% {
    color: #9277ff;
  }
  100% {
    color: #ffffff;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.descriptionBrand {
  width: 39.39vw;
  left: 116px;
  top: 308px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.97vw;
  /* or 133% */
  color: rgba(255, 255, 255, 0.7);
  @media only screen and (max-width: 700px) {
    font-size: 16px;
    width: 90%;
  }
}
.descriptionListCard {
  // width: 39.39vw;
  // left: 116px;
  // top: 308px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 24px;
  /* or 171% */

  color: #7E7E7E;
  @media only screen and (max-width: 700px) {
    font-size: 12px;
    line-height: 24px;
    width: 90%;
  }
}

////---------------CARD SECTION -------------
.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: $tabletAndMobileSize) {
  .columnReverse {
    flex-direction: column-reverse!important;
  }
}


.cardAndTextWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  background: #FFFFFF;
  margin-top: 5%;
  border-radius: 7px;
  img {
    width: auto;
    position: relative;
    top: 0;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    flex-direction: column;
    margin-top: 40px;
    height: auto;
    img {
      height: auto;
      width: 100%;
    }
  }
}
.animationImageCardLeft {
  //animation: moveLeft 1.4s ease-out;
}
@keyframes moveLeft {
  0% {
    transform: translate3d(160px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
.animationImageCardRight {
  //animation: moveRight 1.4s ease-out;
}
@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translate3d(-160px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
.marginLeftCard {
  margin-left: 6.04vw;
  border-radius: 50px 0 0 50px;
  img {
    left: -11px;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    margin-left: unset;
    border-radius: unset;
    text-align: left;
    img {
      left: unset;
    }
  }
}
.marginRightCard {
  margin-right: 6.04vw;
  img {
    right: -11px;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    margin-right: unset;
    border-radius: unset;
    text-align: left;
    img {
      right: unset;
    }
  }
}
.rowReverse {
  @media only screen and (min-width: $tabletAndMobileSize) {
    flex-direction: row-reverse;
  }
}
.textAreaWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 4%;

  ul {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    position: relative;
    height: auto;
    margin-left: unset;
    padding: 5%;
    p {
      margin: 3vh 0;
    }
    .titleStyle {
      font-size: 10px;
    }
    .brandNameCard {
      font-size: 18px;
    }
  }
}
.textLeft {
  left: 3vw;
  @media only screen and (max-width: $tabletAndMobileSize) {
    left: unset;
  }
}
.textRight {
  width: 100%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    right: unset;
    width: auto;
  }
}
.imageLeft {
  left: 0;
}
.imageRight {
  right: 0;
}
// .hideImage {
//   visibility: hidden;
// }
.bulletPointText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  padding-inline-start: 22px;
  margin: 0;
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 12px;
    line-height: 24px;
  }
}

//---------------contact us section----------
.contactUs {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactUsWrapper {
  width: 90%;
  height: 27.2vmax;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  margin-top: 3%;
  position: relative;
  // background-color: rgba(27, 27, 27, 1);
  // background: linear-gradient(0deg, #1b1b1b, #1b1b1b), url("");
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  p {
    color: #7E7E7E;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

.addsomeImage {
  position: absolute;
  left: 12%;
  width: auto;
  height: 2.11vmax;
  @media only screen and (max-width: $tabletAndMobileSize) {
    width: 50%;
    height: auto;
    top: 35%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
}

.contactUsBackgroundImd {
  height: 19.372vmax;
  position: absolute;
  left: 10%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 50%;
    top: 5%;
    left: 28%;
  }
}

.textAndButtonArea {
  width: 100%;
  margin-right: 10%;
  > p {
    background-color: unset;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.9vw;
    // color: #ffffff;
    margin-right: 3vw;
  }
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 2vw;
  @media only screen and (max-width: $tabletAndMobileSize) {
    flex-direction: column;
    justify-content: center;
    margin-top: 30%;
    margin-right: 0;
    > p {
      font-size: 3vw;
      margin-right: 0;
    }
  }
}

.findMoreButtonWrapper {
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: unset;
  }
}

// MODAL
.entireModalWrapper {
  display: flex;
  width: 100%;
  padding: 0 !important;
  height: 503px;
  background-color: #f0f0f0 !important;
  border: unset;
  .addsomeImage {
    height: 25px;
  }
}
.entireModalWrapper{
  height: 510px;
}
.entireModalWrapper:focus {
  outline: none;
}
.leftSideModal {
  width: 35%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  .contactUsBackgroundImd {
    // height: 358px;
    position: inherit;
  }
  .addsomeImage {
    left: 6%;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    display: none;
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 65%;
  // img {
  //   width: 40px;
  //   margin-bottom: 17px;
  // }
  h2 {
    font-size: 16px;
    font-weight: bold;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  p {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
  }
  button {
    // argin: 19px;
    // font-size: 15px;
    // height: 38px;
    // width: 169px;
    // border-radius: 10px;
    border: none;
    // box-shadow: 1px 1px 0px 2px rgba 0, 0, 0, 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    margin-top: 66px;
    width: 27.7vmax;
    text-align: start;
    font-family: "GT America";
    // &:hover {
    //   background: #c8c8c8;
    // }
    cursor: pointer;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    width: 100%;
    align-items: center;
    button {
      width: 100%;
    }
  }
}

.modalWrapper:focus {
  outline: none;
}
.modalWrapperBasic {
  img {
    width: 40px;
    margin-bottom: 17px;
  }
  width: 100%;
}
.inputField {
  margin: 6px 0;
  // width: 13.69vmax;
  height: 49px;

  p {
    text-align: start;
    color: red;
    margin: 5px;
    font-weight: normal;
  }
}
.inputBackgorund {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #151515;
    -webkit-text-fill-color: #ffffff80;
    -webkit-box-shadow: 0 0 0px 1000px #151515 inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}
.subcategoryOfInputs {
  display: flex;
  gap: 15px;
}
.formStyle {
  display: flex;
  width: 521px;
  height: 264px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
.filtersContainer {
  display: flex;
  width: 90%;
  gap: 3%;
  padding: 2%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    > div {
      width: 17%;
    }
    flex-wrap: wrap;
    justify-content: center;
    margin: 3%;
    row-gap: 20px;
  }
  .filterButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
.budgetFilter{
    margin: 5%;
    margin-bottom: 0;
    @media only screen and (max-width: $tabletAndMobileSize) {
      width: 82%;
    }
}
.objectProductCategories {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $tabletAndMobileSize) {
    flex-wrap: wrap;
  }
}
.objectProductSubcategory {
  cursor: pointer;
  opacity: 0.6;
}
.objectProductSubcategory:hover {
  text-decoration: underline;
}
.MuiPaper-root {
  *::-webkit-scrollbar {
    height: 10px;
  }
  div::-webkit-scrollbar-track {
    background: whitesmoke;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #EAEAEA;
    border-radius: 20px;
  } 
}
.styleTags {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  .styleTag {
    cursor: pointer;
    span:hover {
      text-decoration: underline;
    }
    img {
      border-radius: 10px;
    }
  }
}
.roomTags {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  .roomTag {
    cursor: pointer;
    span:hover {
      text-decoration: underline;
    }
    img {
      border-radius: 10px;
    }
  }
}
.brandsWrapper{
  display: flex;
  flex-direction: column;
  img {
    border-radius: 20px;
    border: 0.5px solid gray;
    margin-right: 10px;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.8;
  }
  .brandsLexique {
    display: flex;
    flex-wrap: wrap;
    .brandLexique {
      width: 20%;
      @media only screen and (max-width: $tabletAndMobileSize) {
        width: 100%;
      }
      .brandLexiqueName {
        cursor: pointer;
        opacity: 0.8;
      }
      .brandLexiqueName:hover{
        text-decoration: underline;
      }
    }
  }
}
.colorTags {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  .colorTag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    h6{
      margin-top: 5px;
      opacity: 0.7;
    }
  }
  .buttonColor {
    height:50px;
    width: 50px;
    border-radius: 50%;
    padding: 5px;
    background-color: transparent;
    border: 0.5px solid #EAEAEA;
    .colorAvatar {
      height:100%;
      width: 100%;
      border-radius: 50px;
    }
  }
}
.origins {
  display: flex;
  flex-direction: column;
  min-width: 12.5rem;
  padding: 12px;

  .origin:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .origin {
    margin-bottom: 0.3rem;
  }

  .origin-country {
    padding-left: 1rem;
    opacity: 0.6
  }
}
.materialTags {
  .materialTag {
    padding: 3%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .materialTag:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.applyFilters{
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
}
.sizes {
  .size {
    margin: 7%;
    p {
      margin-bottom: 15%;
      margin-left: -5%;
    }
  }
}
.activeFiltersContainer {
  width: 90%;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
