@mixin commonStyleLoader {
  display: flex;
  flex-direction: column;

  width: 52rem;
  height: 6rem;

  z-index: 1000;
  position: absolute;
  left: calc(50% - 26rem);
  top: calc(50% - 3rem);
}

.container {
  height: 100%;
  width: 100%;
  position: relative;

  .loading {
    @include commonStyleLoader;
    font-size: 1.2rem;

    p {
      margin-top: 2em;
      text-align: center;
    }
  }
}
.loaderGLB {
  font-size: 40px;
  @include commonStyleLoader;

  p {
    margin-top: 2em;
    text-align: center;
    font-size: 1.2rem;
    @media (max-width: 669px) {
      max-width: 30%;
      align-self: center;
    }
  }
}

.canvasStyle {
  @media (min-width: 670px) {
    width: 100%;
    height: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 669px) {
    height: 204px;
  }
}
