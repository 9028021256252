$tabletAndMobileSize: 760px;

.brandWrapper {
  .button {
  }
  // @media only screen and (max-width: 1000px) {
  //   width: 15.7vh;
  //   height: 3vh;
  //   font-size: 1vh;
  // }
  .brandsDescriptionWrapper {
    background-color: #F0F0F0;
    .logoSection {
      background-color: #F0F0F0;
    }
  }
  .textAreaWrapperBrandsArchitects .brandName {
    color: #151515;
  }
  .textAreaBrands {
    padding-bottom: 0;
  }
}

.shortDescriptionSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 17.062vw;
  margin: 1vw 0;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: auto;
    margin: 4vh 0;
  }
}

.whiteText {
  font-family: "Arkitech Medium";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 0.86vw;
  text-align: center;
  color: #ffffff;
  margin: 5px 0;
  margin-block-start: 4px;
  margin-block-end: 4px;
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 0.84vmax;
  }
}
.coloredTextSize {
  color: #9277ff;
  font-size: 1.83vw;
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 16px;
  }
}
.descriptionText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.97vw;
  text-align: center;
  width: 50%;
  margin-top: 3vw;
  color: rgba(255, 255, 255, 0.7);
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 16px;
    margin-top: 60px;
    width: 75%;
  }
}

.shortSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin:2vw 0; 
  p {
    font-family: "Arkitech Medium";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 0;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    margin:4vh 0; 

  }
}
.arrangeButton{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding:4vw 0;
  background: black;
  background-image: url("../../../assets/images/seeMoreDetailsBackrgound.jpg");

  
}

