  $tabletAndMobileSize: 760px;
$XXLSize: 1400px;

.cardsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 7% 0;
  .credits {
    span {
      font-size: 12px;
      line-height: 24px;
    }
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    width: 85%;
    flex-direction: column;
    margin: 15% 0;
  }
  // @media only screen and (min-width: $XXLSize) {
  //   width: 65%;
  // }
}

.centerLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95vh;
  animation: flickerAnimation 4s infinite;
  img {
    height: 20%;
  }
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.4; }
  100% { opacity:1; }
}

.bulletPointText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9vw;
  color: #7E7E7E;

  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 12px;
    line-height: 24px;
  }
}

.textAreaWrapperBrandsArchitects {
  .brandName {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 1.6vw;
    color: #ffffff;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    position: inherit;
    margin-top: 0;
    .brandName {
      color: #151515;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.advSection{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.graphicArea {
  border-radius: 5px;
  width: 90%;
  position: absolute;
  top: 98%;
  border: 1px solid #F0F0F0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  // @media only screen and (min-width: $XXLSize) {
  //   width: 65%;
  // }
  .chartArea{
    display: flex;
    border: none;
    margin:0;
    div {
      width: 33%;
      padding: 4% 2%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #F0F0F0;
      position: relative;

      .chartWrapper{
        border-right: none;
      }

      span {
        position: absolute;
        text-align: center;
        .charNo {

          margin-top: 0;
          margin-bottom: 0 !important;
        }
      }

        p:first-child {
          font-family: 'Inter';
          font-weight: 600;
          font-size: 1.6vw;
          color: #151515;
          margin-bottom: 20%;
        }

        p:nth-child(2) {
          margin-bottom: 0;
          margin-top: 5%;
        }
    }
  }
  .designersTitle {
    background-color: #F0F0F0;
    color: #7E7E7E;
    font-family: "Arkitech Medium";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    padding: 0.7% 5%;
  }
  .flexArea{
    display: flex;
    padding: 3% 5%;
    justify-content: space-between;
    .designersDescription{
      h1 {
        margin-top: 0;
      }
    }
    img {
      max-width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    margin-top: 10vw;
    position: inherit;
    .chartArea {
      flex-direction: column;
      div {
        width: 100%;
        p:first-child {
          font-size: 18px;
          margin-bottom: 10%;
        }
        span {
          font-size: 16px;
        }
        p:nth-child(4) { 
          font-size: 12px;
        }
      }
      img {
        height: 138px;
        width: 138px;
      }
    }
  }
}

// .textArea {
//   top: 96%;
//   .chartArea{
//     border: none;
//     margin: 0;
//     div {
//       display: flex;
//       flex-direction: column;
//       padding: 4% 2%;

//       p {
//         font-family: 'Inter';
//         font-weight: 600;
//         font-size: 1.6vw;
//         color: #151515;
//         margin-bottom: 20%;
//       }
//     }
//   }
// }

.brandsTitle {
  background-color: #F0F0F0;
  color: #7E7E7E;
  font-family: "Arkitech Medium";
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  padding: 0.3% 0;
  text-align: center;
  width: 95.5%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 10px;
    width: auto;
    padding-top: 1%;
  }
}

.cardStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  .counter {
    text-align: left!important;
    font-family: "Arkitech Medium";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5vw !important;
    line-height: 30px;
    color: #ffab47;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .counter-brands {
    width: 8vw!important;
  }
  .counter-products {
    width: 14vw!important;
  }
  .counter-3ds {
    width: 16vw!important;
  }
  p:first-child {
    font-family: "Arkitech Medium";
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 30px;
    /* identical to box height, or 88% */
    text-align: center;
    color: #ffab47;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  p:nth-last-child(1) {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    text-align: center;
    color: #ffffff;
  }
  img {
    width: 50%;
  }
  p {
    margin: 0;
  }
  width: 32.5%;
  height: 36.46vw;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 2.81vmax 0;
  flex: 1;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 111vw;
    width: 100%;
    flex: unset;
    .counter {
      font-size: 11vw!important;
    }
    .counter-brands {
      width: 30%!important;
    }
    .counter-products {
      width: 62%!important;
    }
    .counter-3ds {
      width: 72%!important;
    }
  }
}

.spaceBetween {
  justify-content: space-between;
}
.descriptionSection {
  width: 100%;
  height: 7.84vw;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vw 0;
  p {
    width: 70%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 28vh;
  }
}.descriptionSectionArchitects {
  width: 100%;
  height: 26vw;
  background-color: #F0F0F0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20%;
  p {
    width: 50%;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9vw;
    line-height: 24px;
    text-align: center;
    color: #7E7E7E;
    margin-top: 2%;
  }
  .brandNameCard {
    font-family: 'Inter';
    width: 30%;
    text-align: center;
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 23vh;
    .brandNameCard {
      width: 90%;
      text-align: center;
      font-size: 5vw;
    }
    p {
      width: 90%;
      font-size: 12px;
      line-height: 24px;
    }
  }
  // @media only screen and (min-width: $XXLSize) {
  //   height: 10vw;
  // }
}

.quoteWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.quoteStyle {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 88.01vw;
  height: 25.99vw;
  background: linear-gradient(0deg, #1b1b1b, #1b1b1b);
  border-radius: 50px;
  margin: 5vw 0;
  gap: 3vw;
  text-align: center;
  p:nth-last-child(2) {
    font-family: "Montserrat";
    font-style: italic;
    font-weight: 500;
    font-size: 0.97vmax;
    width: 60%;
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
  }
  p:nth-last-child(1) {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 0.97vmax;
    text-align: center;
    color: #ffab47;
    span {
      font-weight: bold;
    }

    img{
      height: 51px;
    }
  }
  @media only screen and (max-width: $tabletAndMobileSize) {
    width: 90%;
    height: 40vh;
    gap: 4vh;
    p:nth-last-child(2) {
      font-size: 12px;
      width: 80%;
    }
  }
}

.margins {
  margin: 5vw 0vw 5vw 0vw;
}
.textCardStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30.5%;
  height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 3vw 3vw;
  justify-content: flex-start;
  @media only screen and (max-width: $tabletAndMobileSize) {
    justify-content: space-around;
  }
  text-align: center;
  flex: 1;
  border: 1px solid #EAEAEA;
  background: linear-gradient(180deg, #151515 54.69%, #1B1B1B 99.99%, #212121 100%);
  a {
    color: inherit;
    cursor: pointer;
    text-decoration: unset;
  }
  .radioPackCredits {

    .wrapper {
      --font-color-dark: #323232;
      --font-color-light: #FFF;
      --bg-color: #fff;
      --main-color: #323232;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: var(--bg-color);
      border: 2px solid var(--main-color);
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      margin-top: 10%;
      padding: 3% 0;
      width: 9vw;
    }
    
    .option {
      position: relative;
    }
    
    .input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      appearance: none;
      cursor: pointer;
      margin: 0;
    }
    
    .btn {
      height: 4vh;
      width: 2.5vw;
      background-color: var(--bg-color);
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .span {
      color: var(--font-color-dark);
    }
    
    .input:checked + .btn {
      background-color: var(--main-color);
    }
    
    .input:checked + .btn .span {
      color: var(--font-color-light);
    }
  }
  .radioSubscriptionCredits {

    .wrapper {
      --font-color-dark: #323232;
      --font-color-light: #FFF;
      --bg-color: #fff;
      --main-color: #323232;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: var(--bg-color);
      border: 2px solid var(--main-color);
      border-radius: 34px;
      display: flex;
      flex-direction: row;
      margin-top: 10%;
      padding: 3% 0;
      width: 11vw;
    }
    
    .option {
      position: relative;
    }
    
    .input {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      appearance: none;
      cursor: pointer;
      margin: 0;
    }
    
    .btn {
      height: 4vh;
      width: 2.5vw;
      background-color: var(--bg-color);
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .span {
      color: var(--font-color-dark);
    }
    
    .input:checked + .btn {
      background-color: var(--main-color);
    }
    
    .input:checked + .btn .span {
      color: var(--font-color-light);
    }
  }

  p:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #151515;
  }
  p:nth-child(2) {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 85%;
    text-align: center;
    color: #151515;
    padding-bottom:2vh;
    @media only screen and (max-width: $tabletAndMobileSize) {
      padding-bottom: 0;
    }
  }
  p:nth-child(3) {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    width: 85%;
    text-align: center;
    color: #7E7E7E;
  }
  p {
    margin: 0;
  }

  @media only screen and (max-width: $tabletAndMobileSize) {
    min-height: 130vw;
    padding: 8vw 3vw;
    width: 100%;
    p {
      font-size: 3.5vw;
    }
    .buttonCards{
      width: 150px;
      height: 30px;
    }
    .details {
      span {
        font-size: 25px;
      }
    }
    .radioPackCredits {
      .wrapper {
        width: 100%;
      }
      .btn {
        width: 8.5vw;
      }
    }
    .radioSubscriptionCredits {
      .wrapper {
        width: 100%;
      }
      .btn {
        width: 8.5vw;
      }
    }
  }
}

.registerButton {
  margin-top: 3%;
  margin-bottom: 3%;
}

.buttonCards {
  width: 255px;
  height: 40px;
  border: 1px solid #151515;
  color: #151515;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1vw;
  height: 4vw;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 2vw;
    text-align: center;
    letter-spacing: -0.04em;
    color: #151515;
    margin: 10% 0;
  }
  
  @media only screen and (max-width: $tabletAndMobileSize) {
    gap: 2vh;
    span:nth-last-child(2){
      font-size: 5vw;
    }
    span:nth-last-child(3) {
      font-size: 3vw;
    }
  }
}
.subscriptionDescriptionWrapper {
  height: 20%;
  @media only screen and (max-width: $tabletAndMobileSize) {
    height: 10%;
  }
}

.subscriptionDescription {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 85%;
  text-align: center;
  color: #7E7E7E;
  @media only screen and (max-width: $tabletAndMobileSize) {
    font-size: 9px;
    line-height: 12px;
  }
}
